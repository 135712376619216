import React, { useCallback, useEffect, useState } from 'react';
import Page from '../Page';
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import "../../components/customScroll.styles.scss";
import { format } from 'date-fns';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading/loading';
import { useUser } from '../../contexts/userContext';
import { TeamMember } from '../../interfaces/TeamMember';
import { Employee } from '../../interfaces/Employee';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../../components/Toggle/toggle.styles.scss";
import { Module } from '../../interfaces/Module';
import { clearCache, fetchData, postData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';

export default function Team() {
    const { user } = useUser();
    const location = useLocation();
    const { name, number, color, id } = location.state || {}; // Defaulting to an empty object if state is undefined
    const navigate = useNavigate();
    const lang = navigator.language || 'en';
    const [loading, setLoading] = useState<boolean>(false);
    const [teamElements, setTeamElements] = useState<TeamMember[]>([]);
    const [filteredTeamsElems, setFilteredTeamsElems] = useState<TeamMember[]>([]);
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalPermissions, setShowModalPermissions] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<Employee[]>([]); // Array of selected members to add to the team
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [editRowVisible, setEditRowVisible] = useState(-1);
    const [selectedModules, setSelectedModules] = useState<string[]>([]);
    const [add, setAdd] = useState<boolean>(true);
    const [modules, setmodules] = useState<Module[]>([]);
    const [modulesToShow, setModulesToShow] = useState<Module[]>([]);

    const fetchTeam = useCallback(async () => {
        const idToken = user ? user!.idToken : null;

        setLoading(true);
        const response = await fetchData(`/api/teams/teamInfo/${id}`, idToken);
        if (response.status === 200 || response.status === 201) {
            setTeamElements(response.data.members);
            setFilteredTeamsElems(response.data.members);
        } else {
            Toaster.show('Não foi possível obter as equipas. Tente novamente.', 'error');
            console.error('Get teams failed');
        }

        setLoading(false);
    }, [user, id]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const formatDate = (date: string) => {
        return format(new Date(date), 'yy/MM/dd HH:mm:ss');
    }

    const handleAddMember = (newEmployee: Employee) => {
        if (selectedMembers.includes(newEmployee)) {
            setSelectedMembers(selectedMembers.filter((elem) => elem !== newEmployee));
        } else {
            setSelectedMembers([...selectedMembers, newEmployee]);
        }
    };

    const handleAddModule = (id: string) => {
        if (selectedModules.includes(id)) {
            setSelectedModules(selectedModules.filter((elem) => elem !== id));
        } else {
            setSelectedModules([...selectedModules, id]);
        }
    };


    const toggleCancel = () => {
        setShowModal(false);
        setSelectedMembers([]);
    }

    const toggleCancelPermissions = () => {
        setShowModalPermissions(false);
        setSelectedMembers([]);
    }

    const addMember = async () => {
        const idToken = user ? user!.idToken : null;

        setLoading(true);
        const memberEmails = selectedMembers.map((elem) => elem.email);
        if (memberEmails.length === 0) {
            Toaster.show('Selecione pelo menos um membro para adicionar.', 'error');
            setLoading(false);
            return;
        }
        const response = await postData(`/api/teams/${id}/addusers`, {
            userMails: memberEmails
        }, idToken);
        if (response.status === 200 || response.status === 201) {
            Toaster.show('Membros adicionados com sucesso.', 'success');
            setShowModal(false);
            setSelectedMembers([]);
            clearCache(`/api/teams/teamInfo/${id}`);
            clearCache(`/api/teams/teamsManaging`);
            fetchTeam();
        } else {
            Toaster.show('Não foi possível adicionar os membros. Tente novamente.', 'error');
            console.error('Add members failed');
        }
        setLoading(false);
    }



    const toggleEditRow = (index: number) => {
        if (editRowVisible === index) {
            setEditRowVisible(-1);
        } else {
            setEditRowVisible(index);
        }

    };

    const handleCancel = () => {
        toggleEditRow(-1);
    }

    const editPermissions = async () => {
        const idToken = user ? user!.idToken : null;

        setLoading(true);
        const url = add ? `/api/user/addUsersPermissions` : `/api/user/removeUsersPermissions`;
        const response = await postData(url, {
            featureIds: selectedModules,
            userMail: filteredTeamsElems[editRowVisible]?.email
        }, idToken);
        if (response.status === 200 || response.status === 201) {
            Toaster.show('Permissions edited with success.', 'success');
            setShowModalPermissions(false);
            setSelectedModules([]);
            clearCache(`/api/teams/teamInfo/${id}`);
            fetchTeam();
        } else {
            Toaster.show('Unable to edit permissions. try again.', 'error');
            console.error('Add modules failed');
        }
        setLoading(false);
    }


    useEffect(() => {

        const fetchTeams = async () => {
            const idToken = user ? user!.idToken : null;

            setLoading(true);
            const response = await fetchData("/api/teams/teamsManaging", idToken);
            if (response.status === 200 || response.status === 201) {
                setEmployees(response.data.employees);
            } else {
                Toaster.show('Não foi possível obter as equipas. Tente novamente.', 'error');
                console.error('Get teams failed');
            }
            setLoading(false);
        }
        fetchTeams();

    }, [user]);

    useEffect(() => {
        if (modules && filteredTeamsElems && editRowVisible !== -1) {
            if (add) {
                setModulesToShow(modules.filter((elem) => !filteredTeamsElems[editRowVisible]?.features?.map((elem) => elem.name).includes(elem.name)));
            } else {
                setModulesToShow(modules.filter((elem) => filteredTeamsElems[editRowVisible]?.features?.map((elem) => elem.name).includes(elem.name)));
            }
        }
    }, [showModalPermissions]);



    useEffect(() => {
        const fetchModules = async () => {
            const idToken = user ? user!.idToken : null;

            setLoading(true);
            const response = await fetchData("/api/modules/", idToken);
            if (response.status === 200 || response.status === 201) {
                setmodules(response.data);
            } else {
                Toaster.show('Não foi possível obter os modules. Tente novamente.', 'error');
                console.error('Get modules failed');
            }

            setLoading(false);
        }
        fetchModules();

    }, [user]);

    useEffect(() => {
        fetchTeam();
    }, [fetchTeam]);

    useEffect(() => {
        if (search === '') {
            setFilteredTeamsElems(teamElements);
            return;
        }
        const filtered = teamElements.filter((elem) =>
            elem.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredTeamsElems(filtered);
        if (filtered.length === 0) {
            Toaster.show(`Não foram encontrados resultados para a pesquisa: ${search}`, 'error');
        }
    }, [search, teamElements]);

    return (
        <Page >

            <div className='h-screen'>
                <div onClick={() => navigate("/teams")} className='flex items-center gap-2 w-fit cursor-pointer'>
                    <FaArrowLeft size={24} title={lang === "pt" ? "Voltar" : "Go Back"} />
                    <h2 className='text-xl'>{lang === "pt" ? "Voltar" : "Go Back"}</h2>
                </div>
                <div className="py-10">
                    <div className="flex gap-4 pb-6 items-center">
                        <p className='text-xl text-zinc-500'>{lang === "pt" ? "Equipa" : "Team"}</p>
                        <div style={{ backgroundColor: color }} className={`w-8 h-8 text-white rounded-full flex items-center justify-center`}>{number}</div>
                    </div>
                    <p className='text-lg font-bold pb-6'>{lang === "pt" ? "Membros" : "Members"}</p>
                    <div className="flex items-center gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" placeholder={lang === "pt" ? "Nome" : "Name"} className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                            <span>+</span>
                            <span>{lang === "pt" ? "Novo Membro" : "Add Member"}</span>
                        </button>
                    </div>
                    {(filteredTeamsElems && filteredTeamsElems.length > 0) ?
                        <div className='scroll overflow-auto max-h-[30rem]  w-full'>

                            <div className=' w-full'>
                                <table className=' w-full'>
                                    <thead className="">
                                        <tr className='text-sm text-left text-zinc-500 '>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Nome" : "Name"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                Email
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Data de Criação" : "Criation Date"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Acesso a Módulos" : "Modules Access"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Estado" : "Status"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Ações" : "Actions"}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {filteredTeamsElems.map((elem, index) => (
                                            <React.Fragment key={index}>
                                                <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                    <td className="px-6 py-10">
                                                        <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        <div className="text-sm rounded-2xl ">{elem.email}</div>
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        <div className="text-sm rounded-2xl ">{formatDate(elem.created_at)}</div>
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        {elem.features?.map((feature, index) => (
                                                            <div key={index} className="text-sm rounded-2xl ">{feature.name}</div>
                                                        ))}
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        <Toggle
                                                            defaultChecked={elem.status}
                                                            disabled />
                                                    </td>
                                                    <td className="px-6 py-10 text-sm font-medium">
                                                        <button onClick={() => toggleEditRow(index)}
                                                            className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className={`${editRowVisible !== index && "hidden"}   border-b-2 border-zinc-200`}>
                                                    <td colSpan={6}>
                                                        <div className="flex flex-col gap-8 pb-10 px-6 w-full">
                                                            <div className='flex justify-end gap-8'>
                                                                <button onClick={handleCancel} className='bg-zinc-200 text-black py-2 px-4 rounded-lg'>
                                                                    Cancel
                                                                </button>
                                                                <button onClick={() => { setShowModalPermissions(true); setAdd(false) }} className='bg-red-500 text-white py-2 px-4 rounded-lg'>
                                                                    Remove Module Permissions
                                                                </button>
                                                                <button onClick={() => { setShowModalPermissions(true); setAdd(true) }} className='bg-dashBlue text-white py-2 px-4 rounded-lg'>
                                                                    Add Module Permissions
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <div className='flex justify-center items-center h-96 text-zinc-500'>
                            {lang === "pt" ?
                                (search === '' ? "Não existem membros. Adicione um membro à equipa clicando no botão no canto superior direito." : `Sem resultados para ${search}`)
                                : (search === '' ? "There are no team members. Add a new member in the button." : `No results for ${search}`)}
                        </div>}
                </div>
            </div>
            <Modal isOpen={showModal} handler={addMember}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Membro" : "Add Member"}</p>
                            <div className="flex gap-4 items-center">
                                <p className='text-sm text-zinc-500'>{name}</p>
                            </div>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />

                            <p className='text-sm'>{lang === "pt" ?
                                "Selecione da lista os elementos que deseja adicionar à equipa" :
                                "Select from the list the elements you want to add to the team"}
                            </p>
                        </div>
                    </div>
                    <div className='scroll overflow-x-auto max-h-96 pr-2'>

                        <table className="min-w-full">
                            <thead className="">
                                <tr className='text-sm text-left text-zinc-500 '>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Nome" : "Name"}
                                    </th>
                                    <th className="px-6 py-3 ">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Selecionar" : "Select"}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {employees && employees.map((elem, index) => (
                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                        <td className="px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <div className="text-sm rounded-2xl ">{elem.email}</div>
                                        </td>
                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                            <div
                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedMembers.includes(elem) ? 'bg-blue-600 ' : ''}`}
                                                onClick={() => handleAddMember(elem)}
                                            >
                                                <FaCheck className='text-white' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={addMember} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
            <Modal isOpen={showModalPermissions} handler={editPermissions}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? (add ? "Adicionar Módulo" : "Remover Módulo") : (add ? "Add Module" : "Remove Módule")}</p>
                            <div className="flex gap-4 items-center">
                                <p className='text-sm text-zinc-500'>{name}</p>
                            </div>
                        </div>
                        <IoIosClose onClick={() => setShowModalPermissions(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />

                            <p className='text-sm'>{lang === "pt" ?
                                (add ? "Selecione da lista de móduleos que deseja adicionar ao utilizador" : "Selecione da lista de móduleos que deseja remover do utilizador") :
                                (add ? "Select from the list of modules you want to add to the user" : "Select from the list of modules you want to remove from the user")}
                            </p>
                        </div>
                    </div>
                    <div className='scroll overflow-x-auto max-h-96 pr-2'>
                        {(modulesToShow && modulesToShow.length > 0) ? <table className="min-w-full">
                            <thead className="">
                                <tr className='text-sm text-left text-zinc-500 '>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Nome" : "Name"}
                                    </th>
                                    <th className="px-6 py-3 ">
                                        Description
                                    </th>
                                    <th className="px-6 py-3 ">
                                        Status
                                    </th>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Selecionar" : "Select"}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {modulesToShow.map((elem, index) => (
                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                        <td className="px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <div className="text-sm rounded-2xl ">{elem.description}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <Toggle
                                                defaultChecked={elem.order_item_status}
                                                disabled />
                                        </td>
                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                            <div
                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedModules.includes(elem.id!) ? 'bg-blue-600 ' : ''}`}
                                                onClick={() => handleAddModule(elem.id!)}
                                            >
                                                <FaCheck className='text-white' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                            :
                            <div className='flex justify-center items-center h-96'>
                                <p className='text-lg text-zinc-500'>{lang === "pt" ? "Sem módulos disponiveis para a operação" : "No available modules for the operation"}</p>
                            </div>}
                    </div>
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancelPermissions} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={editPermissions} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? (add ? "Adicionar" : "Remover") : (add ? "Add" : "Remove")}</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}