import axios from 'axios';
import Toaster from './Toaster';



function getCacheKey(endpoint: string): string {
    const label = endpoint.replaceAll("/", "_");
    return label;
}

export function setCache(endpoint: string, data: any): void {
    sessionStorage.setItem(getCacheKey(endpoint), JSON.stringify(data));
}

function getCache(endpoint: string): any {
    const cachedData = sessionStorage.getItem(getCacheKey(endpoint));
    return cachedData ? JSON.parse(cachedData) : null;
}

export function clearCache(endpoint: string): void {
    sessionStorage.removeItem(getCacheKey(endpoint));
}

export async function fetchData(endpoint: string, idToken: string | null | undefined): Promise<any> {
    if (!idToken) {
        Toaster.show('Ocorreu um erro. Tente novamente mais tarde.', 'error');
        console.error('No token found.');
        return;
    }

    const cachedData = getCache(endpoint);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${idToken}`
            },
            withCredentials: true
        });
        const result = { data: response.data, status: response.status };
        setCache(endpoint, result);
        return result;
    } catch (error) {
        const errorMessage = (error as any).message || 'Unknown error';
        const errorStatus = (error as any).response?.status || 'No status';
        const errorData = (error as any).response?.data || 'No response data';

        console.error('Fetch Data Error:', {
            message: errorMessage,
            status: errorStatus,
            data: errorData
        });        if ((error as any).response?.status === 404) {
            return { data: null, status: 404 };
        } else {
            return { data: null, status: 500 };
        }
    }
}

export async function postData(endpoint: string, data: any, idToken: string | null | undefined): Promise<any> {
    if (!idToken) {
        Toaster.show('Ocorreu um erro. Tente novamente mais tarde.', 'error');
        console.error('No token found.');
        return;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, data, {
            headers: {
                Authorization: `Bearer ${idToken}`
            },
            withCredentials: true
        });
        const result = { data: response.data, status: response.status };
        return result;
    } catch (error) {
        console.error('Post Data Error:', error);
        return { data: null, status: 500 };
    }
}

export async function putData(endpoint: string, data: any, idToken: string | null | undefined): Promise<any> {
    if (!idToken) {
        Toaster.show('Ocorreu um erro. Tente novamente mais tarde.', 'error');
        console.error('No token found.');
        return;
    }

    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}${endpoint}`, data, {
            headers: {
                Authorization: `Bearer ${idToken}`
            },
            withCredentials: true
        });
        const result = { data: response.data, status: response.status };
        return result;
    } catch (error) {
        console.error('Put Data Error:', error);
        return { data: null, status: 500 };
    }
}

export async function deleteData(endpoint: string, idToken: string | null | undefined, data?: any): Promise<any> {
    if (!idToken) {
        Toaster.show('Ocorreu um erro. Tente novamente mais tarde.', 'error');
        console.error('No token found.');
        return;
    }

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${idToken}`
            },
            data: data,
            withCredentials: true
        });
        const result = { data: response.data, status: response.status };
        return result;
    } catch (error) {
        console.error('Delete Data Error:', error);
        return { data: null, status: 500 };
    }
}