import React, { useCallback, useEffect, useState } from 'react';
import Page from '../Page';
import { IoCard, IoHelpCircleSharp } from "react-icons/io5";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/loading';
import { useUser } from '../../contexts/userContext';
import { clearCache, fetchData, putData, postData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import "../../components/customScroll.styles.scss";
import { CartItem } from '../../interfaces/CartItem';
import Modal from '../../components/Modal';
import { RiPaypalFill } from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';

interface PaymentInfo {
    totalAmount: number;
    names: string[];
    images: string[];
  }

export default function ShoppingCart() {
    const lang = navigator.language || 'en';
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useUser();
    const [cart, setCart] = useState<CartItem[]>([]);
    const [filteredCart, setFilteredCart] = useState<CartItem[]>([]);
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isCardChecked, setCardChecked] = useState(false);
    const [isPayPalChecked, setPayPalChecked] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };





    const deleteFromCart = async (id: number) => {
        const idToken = user ? user!.idToken : null;

        const featureIds = {
            featureIds: [id]
        };

        setLoading(true);
        const response = await putData(`/api/order/removeitems`, featureIds, idToken);
        if (response.status === 200 || response.status === 201) {
            Toaster.show('Módulo removido do carrinho.', 'success');
            clearCache('/api/order/cart');
            const updatedCart = cart.filter(item => item.feature_id !== id);
            setSearch('');
            setCart(updatedCart);
            setFilteredCart(updatedCart);
        } else {
            Toaster.show('Não foi possível remover o módulo do carrinho. Tente novamente.', 'error');
            console.error('Delete from cart failed');
        }
        setLoading(false);
    }




    const getCartSummary = useCallback(() => {
        let totalAmount = 0;
        const names: string[] = [];
        const images: string[] = [];

        cart.forEach(item => {
            totalAmount += Number(item.price);
            names.push(item.name);
            if (item.images) {
                item.images.forEach(image => images.push(image));
            }
        });

        return { totalAmount, names, images };
    }, [cart]);

    const handleCardPayment = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

        if (!stripe) {
            return;
        }
        const idToken = user ? user!.idToken : null;

        setLoading(true);

        const type = isCardChecked ? 'card' : 'paypal';
        const response = await postData(`/api/order/cart/pay`, {
            payment: { amount: paymentInfo?.totalAmount, currency: "eur", payment_type: type },
            product: { name: paymentInfo?.names.join(", "), images: paymentInfo?.images },
            customer: { email: user?.email }
        }, idToken);

        if(response.data){
            const sessionId = response.data.sessionId;

            sessionStorage.setItem(`paymentIntent`, true.toString());
            const result = await stripe.redirectToCheckout({
                sessionId: sessionId,
            });
            if (result.error) {
                console.log("[error]", result.error);
                 Toaster.show(`Pagamento falhou: ${result.error.message}`, 'error');
             }
        } else{
            Toaster.show('Não foi possível efetuar o pagamento. Tente novamente.', 'error');
        }

        setLoading(false);


    };

    const toggleCancel = () => {
        setShowModal(false);
    }

    useEffect(() => {
        setPaymentInfo(getCartSummary());
    }, [getCartSummary]);



    useEffect(() => {
        const fetchCart = async () => {
            const idToken = user ? user!.idToken : null;

            setLoading(true);
            const response = await fetchData("/api/order/cart", idToken);
            if (response.status === 200 || response.status === 201) {
                setCart(response.data);
                setFilteredCart(response.data);
                console.log(response.data);

            } else {
                Toaster.show('Não foi possível obter as orders. Tente novamente.', 'error');
                console.error('Get cart failed');
            }
            setLoading(false);
        };
        fetchCart();
    }, [user]);


    useEffect(() => {
        if (search === '') {
            setFilteredCart(cart);
            return;
        }
        //if search is number filter by number, else filter by name
        const filtered = cart.filter((order) =>
            order.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCart(filtered);

    }, [search, cart]);

    return (
        <Page >

            <div className='h-screen'>
                <div className='flex justify-between items-center'>
                    <h2 className='text-xl'>{lang === "pt" ? "Carrinho" : "Cart"}</h2>
                    <button onClick={() => setShowModal(true)} className="bg-dashBlue w-fit text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <span>Buy Order</span>
                    </button>
                </div>
                <div className="py-6">
                    <div className="flex items-center gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome do módulo.
                                        Poderá adicionar módulos ao carrinho a partir do marketplace.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(filteredCart && filteredCart.length > 0) ? <div className='scroll overflow-x-auto max-h-96 h-96 pr-2'>
                        <table className="min-w-full">
                            <thead>
                                <tr className='text-sm text-left text-zinc-500'>
                                    {['Nome', 'Preço', 'Actions'].map((header) => (
                                        <th key={header} className="px-4 cinco:px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='overflow-y-auto '>
                                {filteredCart.map((elem, index) => (
                                    <tr key={index} className='text-zinc-500 border-t-2 border-zinc-200'>
                                        <td className="px-4 cinco:px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl">{elem.name}</div>
                                        </td>
                                        <td className="px-4 cinco:px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl">{elem.price}€</div>
                                        </td>
                                        <td className="px-4 cinco:px-6 py-10 text-sm font-medium">
                                            <button onClick={() => Toaster.showWithHandler('Tem a certeza que deseja remover o módulo do carrinho?', () => deleteFromCart(elem.feature_id), { duration: 5000 })}
                                                className="text-sm font-bold text-red-400 hover:text-red-500">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        : <div className='flex justify-center'>
                            {search === '' ?
                                <div className='flex gap-1'>
                                    <p>Não existem módulos no carrinho.</p>
                                    <Link to={'/marketplace'} className='text-dashBlue'>Adicionar módulos</Link>
                                </div>
                                : <p>Não foram encontrados resultados para a pesquisa: {search}</p>}
                        </div>}
                </div>
            </div>
            <Modal isOpen={showModal} handler={handleCardPayment}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Módulo" : "Add Módule"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />

                            <p className='text-sm'>{lang === "pt" ?
                                "Selecione da lista os módulos que deseja adicionar" :
                                "Select from the list the modules you want to add"}
                            </p>
                        </div>
                    </div>
                    <div className="py-12 flex gap-4 min-h-full">
                        <div className="w-full ">
                            <div className="p-4">
                                <h2 className="text-2xl font-semibold pb-8">Seleccione o seu método de pagamento</h2>
                                <div className="flex flex-col gap-2">
                                    <div onClick={() => { setCardChecked(!isCardChecked); setPayPalChecked(false); }} className={`flex gap-4 cursor-pointer p-4 rounded-xl border-2 border-solid transition-all ease-in-out duration-500 ${isCardChecked ? "border-[var(--primary)]" : "border-zinc-300"}`}>
                                        <div className="w-24 flex justify-center">
                                            <IoCard className="transition-all ease-in-out duration-500 " size={50} color={isCardChecked ? "var(--primary)" : "black"} />
                                        </div>
                                        <label className={`pl-2 flex transition-all ease-in-out duration-500  justify-center items-center text-${isCardChecked ? "[var(--primary)]" : "black"}`}>Cartão</label>
                                    </div>
                                    <div className={` transition-max-height duration-700 ease-in-out overflow-hidden ${isCardChecked ? 'max-h-screen' : 'max-h-0'}`}>
                                        <div className={`grid grid-cols-1 pb-8 p-4 gap-4 rounded-xl border border-solid border-zinc-300`}>
                                            <div className="pt-4">
                                                <div className="flex justify-center py-4">
                                                    <button onClick={handleCardPayment} className="w-72 bg-red-500 text-white py-3 rounded-lg">Pagar {paymentInfo?.totalAmount.toString()}€</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => { setCardChecked(false); setPayPalChecked(!isPayPalChecked); }} className={`flex gap-4 cursor-pointer p-4 rounded-xl border-2 border-solid transition-all ease-in-out duration-500 ${isPayPalChecked ? "border-[var(--primary)]" : "border-zinc-300"}`}>
                                        <div className="w-24 flex justify-center">
                                            <RiPaypalFill className="transition-all ease-in-out duration-500 " size={50} color={isPayPalChecked ? "var(--primary)" : "black"} />
                                        </div>
                                        <label className={`pl-2 flex transition-all ease-in-out duration-500  justify-center items-center text-${isPayPalChecked ? "[var(--primary)]" : "black"}`}>PayPal</label>
                                    </div>
                                    <div className={`transition-max-height duration-700 ease-in-out overflow-hidden ${isPayPalChecked ? 'max-h-screen' : 'max-h-0'}`}>
                                        <div className={`grid grid-cols-1 pb-8 p-4 gap-4 rounded-xl border border-solid border-zinc-300`}>
                                            <div className="pt-4">
                                                <div className="flex justify-center py-4">
                                                    <button onClick={handleCardPayment} className="w-72 bg-red-500 text-white py-3 rounded-lg">Pagar com PayPal {paymentInfo?.totalAmount.toString()}€</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                    </div>
                </div>
            </Modal>

            <Loading isOpen={loading} />
        </Page>

    );
}

