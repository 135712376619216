import React, { ReactElement, useEffect, useState } from "react";
import "./styles.navbar.css";
import visionD from "../content/images/visionD-removebg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineLogout } from "@react-icons/all-files/hi/HiOutlineLogout";

import { PiCube } from "react-icons/pi";
import { IoReceiptOutline } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import { TbMessageQuestion } from "react-icons/tb";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { useUser } from "../contexts/userContext";
import { GoPeople } from "react-icons/go";


export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [menuActive, setMenuActive] = useState<boolean>(false);
  // const [show, setShow] = useState<boolean>(true);

  const lang = navigator.language || 'en';

  interface CustomLinkProps {
    href: string;
    icon?: ReactElement;
    children: React.ReactNode;
  }

  const CustomLink: React.FC<CustomLinkProps> = ({
    href,
    icon,
    children,
  }) => {

    const index = href.indexOf('/', 1)
    const loc = href.substring(1, index === -1 ? href.length : index);
    let selected = window.location.pathname.includes(loc);
    return (
      <div className="group rounded-full">
        <Link
          to={href}
          className={`flex gap-4 rounded-full p-2 items-center oito:px-4 oito:p-2 transition-all duration-200 ease-linear ${selected ? " text-white bg-dashBlue" : " text-zinc-400 bg-transparent"}`}
        >
          {icon && (
            <span className={`group-hover:${selected ? "text-white" : "text-black "} transition-all duration-200 ease-linear `}>
              {icon} {/* Directly render the icon */}
            </span>
          )}
          <p className={`text-[1rem] font-bold group-hover:${selected ? "text-white" : "text-black "} transition-all duration-200 ease-linear `}>
            {children}
          </p>
        </Link>
      </div>
    );
  };

  function logOut() {
    setUser(null);
    sessionStorage.clear();
    navigate('/')
  }

  const toggleMenu = () => {
    if (!menuActive) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    setMenuActive(false);
    document.body.style.overflow = "auto";
    window.scroll(0, 0);
  }, [location]);

  return (

    <div id="navbar" className={`relative  `}>

      <div
        className={`bg-zinc-100 oito:w-[17rem] w-full ease-linear transition-all duration-200 fixed top-0 left-0 h-full flex flex-col items-start justify-center oito:justify-between oito:h-full px-4 oito:px-6 text-gray py-8 pl-[8vw] oito:py-4 z-[10] oito:translate-y-0 ${menuActive ? "translate-y-0 " : "translate-y-[-100%] "}`}
      >
        <div className={`flex flex-col h-full gap-8`}>
          <div className="flex-row hidden gap-2 oito:items-start oito:flex">
            <div className="pl-4">
              <Link to={"/home"}>
                <img src={visionD} alt="logo" className={`w-[15rem]`} />
              </Link>
              <h2 className="text-[1.2rem] text-[#f8bf59] font-bold pl-4">
                MODULES
              </h2>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full gap-2 pt-12 oito:pt-0">
            <div className="flex flex-col h-full gap-4">
              <CustomLink icon={<PiCube size={24} title="Modules" />} href="/home">
                {lang === "pt" ? "Módulos" : "Modules"}
              </CustomLink>
              <CustomLink icon={<GoPeople size={24} title="Teams" />} href="/teams">
                {lang === "pt" ? "Equipas" : "Teams"}
              </CustomLink>
              <CustomLink icon={<IoReceiptOutline size={24} title="Billing" />} href="/billing/invoiceDocs">
                {lang === "pt" ? "Pagamentos" : "Billing"}
              </CustomLink>

              <CustomLink icon={<IoSettingsOutline size={24} title="Settings" />} href="/settings">
                {lang === "pt" ? "Conta" : "Settings"}
              </CustomLink>
            </div>
            <div className="flex flex-col gap-4 ">
              <CustomLink icon={<TbMessageQuestion size={24} title="Help Center" />} href="/help">
                {lang === "pt" ? "Central de Ajuda" : "Help Center"}
              </CustomLink>
               <CustomLink icon={<TbDeviceDesktopAnalytics size={24} title="Marketplace" />} href="/marketplace">
                  {lang === "pt" ? "Mercado" : "Marketplace"}
                </CustomLink>
                <CustomLink icon={<IoCartOutline size={24} title="Shopping Cart" />} href="/cart">
                  {lang === "pt" ? "Shopping Cart" : "Shopping Cart"}
                </CustomLink>
              <div className="py-4">
                <hr className="border-zinc-400" />
              </div>
              {/* <CustomLink icon={<HiSwitchHorizontal size={24} title="Switch Account" />} href="/switch">
                  {lang === "pt" ? "Mudar de Conta" : "Switch Account"}
                </CustomLink> */}
              <div
                onClick={logOut}
                className={`flex gap-4 rounded-full p-3 items-center oito:px-4 oito:p-3 cursor-pointer`}
              >
                <HiOutlineLogout size={24} className="text-zinc-400"></HiOutlineLogout>
                <p className="text-[1rem] font-bold text-zinc-400 hover:text-black transition-all duration-200 ease-linear">
                  {lang === "pt" ? "Sair" : "Logout"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="oito:hidden fixed top-0 left-0 flex justify-between w-full bg-zinc-100 h-[5rem] items-center px-8 z-[30]">
        <Link to={"/home"}>
          <img src={visionD} alt="logo" className={`w-28 z-[20]`} />
        </Link>
        <div
          onClick={toggleMenu}
          id="menu-btn"
          className={`w-[20px] h-7 flex flex-col justify-evenly items-center z-[20] ${menuActive && "active"}`}
        >
          <div className="menu-btn-bar bg-black relative w-full h-[3px]"></div>
          <div className="menu-btn-bar bg-black relative w-full h-[3px]"></div>
          <div className="menu-btn-bar bg-black relative w-full h-[3px]"></div>
        </div>
      </div>
    </div>
  );
}
