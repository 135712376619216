import { useCallback, useEffect, useState } from 'react';
import modules from '../content/images/modules.png';
import visionD from '../content/images/visionD.png';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading/loading';
import { signInWithEmailPassword } from '../firebase/firebase';
import { User } from '../interfaces/User';
import { useUser } from '../contexts/userContext';
import { validateEmail } from '../components/ValidationUtils';
import { decryptData } from '../components/encrypt_decrypt';
import Toaster from '../components/Toaster';
import eyeClose from '../content/images/eyeClose.svg';
import eyeOpen from '../content/images/eyeOpen.svg';
import template from '../content/welcomeEmail';
import emailjs from 'emailjs-com';

export default function Login() {
    const navigate = useNavigate();
    const { setUser } = useUser();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const company_id = queryParams.get('param1');
    const member = queryParams.get('param2');
    const decripted = decryptData(company_id || '');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const companyId = decripted || '';

    const [signUp, setSignUp] = useState<boolean>(company_id ? true : false);
    const [email, setEmail] = useState<string>(member || '');
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }
    const handleLogin = useCallback(async () => {
        if (!email || !password) {
            Toaster.show('Preencha todos os campos', 'error');
            return;
        }
        if (!validateEmail(email)) {
            Toaster.show('Email inválido.', 'error');
            return;
        }

        setLoading(true);
        try {
            const { message, idToken, refreshToken } = await signInWithEmailPassword(email, password);

            if (message === 'success') {
                const user: User = {
                    name: name,
                    email: email,
                    idToken: idToken!,
                    refreshToken: refreshToken!,
                };
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                navigate('/settings');
            } else {
                Toaster.show('Credenciais inválidas. Tente novamente.', 'error');
                console.error('Login failed');
            }
        } catch (error) {
            Toaster.show('Credenciais inválidas. Tente novamente.', 'error');
            console.error('Login failed', error);
        }
        setLoading(false);
    }, [email, name, password, setUser, navigate]);


    const sendWelcomeEmail = async (email: string) => {
        if (email) {
            const templateParams = {
                to_email: email,
                subject: `Welcome to our Modules`,
                my_HTML: template
            };
            try {
                await emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
                    templateParams,
                    process.env.REACT_APP_EMAILJS_USER_ID || ""
                );
            } catch (error) {
                console.error('Failed to send email', error);
            }
        }

    };


    const handleSignUp = useCallback(async () => {


        if (!email || !password || !name) {
            Toaster.show('Preencha todos os campos', 'error');
            return;
        }
        if (!validateEmail(email)) {
            Toaster.show('Email inválido.', 'error');
            return;
        }
        setLoading(true);
        try {

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
                email: email,
                password: password,
                name: name,
                company_id: companyId
            });
            if (response.status === 200 || response.status === 201) {
                setSignUp(false);
                sendWelcomeEmail(email);
                Toaster.show('Usuário criado com sucesso', 'success');
            } else {
                Toaster.show('Credenciais inválidas. Tente novamente.', 'error');
                console.error('Login failed');
            }
        } catch (error) {
            Toaster.show('Credenciais inválidas. Tente novamente.', 'error');
            console.error('Login failed', error);
        }
        setLoading(false);
    }, [companyId, email, name, password]);

    useEffect(() => {
        const handleKeyDown = async (event: { key: string; }) => {
            if (event.key === 'Enter') {
                if (signUp) {
                    handleSignUp();
                } else {
                    handleLogin();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleLogin, handleSignUp, signUp]);


    return (
        <div className='flex w-full h-full min-h-screen mil:bg-white bg-[var(--primary)] items-center overflow-hidden'>

            <div className={`${signUp ? "w-0 invisible opacity-0 mil:hidden" : "w-full opacity-100 mil:w-[45%]"} transition-all duration-300 ease-out  text-black dark-bg-image h-full`}>
                <div className={`flex flex-col justify-center h-full gap-8 px-2 cinco:px-10 mile400:px-15`}>
                    <div className='m-auto'>
                        <img src={visionD} alt="logo" className='w-[20rem] text-left hidden mil:block' />
                        <div className='mil:hidden flex flex-col gap-2'>
                            <img src={modules} alt="logo" className='w-[20rem] text-left' />
                            <p className='text-white text-sm text-center'>Powered by VisionD</p>
                        </div>
                    </div>
                    <div className='px-5 cinco:px-10 mile400:px-15'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div className="relative">
                                <button
                                    tabIndex={-1}
                                    type='button'
                                    className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-3"
                                    onClick={togglePasswordVisibility}
                                >
                                    {!isPasswordVisible ? (
                                        <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                    ) : (
                                        <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                    )}
                                </button>
                                <div className='flex flex-col gap-2'>
                                    {/* <label className="text-lg font-semibold text-white mil:text-black">Password</label> */}
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder='| Password'
                                        className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                            </div>
                            <button onClick={handleLogin} className="font-bold w-full text-white bg-dashBlue hover:bg-dashBlueDark focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-xl text-2xl px-5 py-3.5 text-center">Login</button>
                            <div className='m-auto'>
                                <div className="flex flex-col gap-4 text-center">
                                    <div className="flex gap-4 text-lg font-semibold text-center text-white mil:text-black">
                                        <p>Or</p>
                                        <div className='hover:underline cursor-pointer underline-offset-4 text-blue-400' onClick={() => setSignUp(true)}>
                                            Sign Up
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex mil:hidden justify-end pt-20 px-5  cinco:px-10 mile400:px-15'>
                        <p className='w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
                    </div>
                </div>
            </div>

            <div className={`${!signUp ? "w-0 opacity-0 hidden" : "w-full opacity-100 mil:translate-x-[120%]"} transition-all duration-500 ease-in  text-black dark-bg-image mil:w-[45%] h-full`}>
                <div className={`flex flex-col justify-center  h-full gap-8 px-2 cinco:px-10 mile400:px-15`}>
                    <div className='m-auto'>
                        <img src={visionD} alt="logo" className='w-[20rem] text-left hidden mil:block' />
                        <div className='mil:hidden flex flex-col gap-2'>
                            <img src={modules} alt="logo" className='w-[20rem] text-left' />
                            <p className='text-white text-sm text-center'>Powered by VisionD</p>
                        </div>
                    </div>
                    <div className='px-5 cinco:px-10 mile400:px-15'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Nome'
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div className="relative">
                                <button
                                    tabIndex={-1}
                                    type='button'
                                    className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-3"
                                    onClick={togglePasswordVisibility}
                                >
                                    {!isPasswordVisible ? (
                                        <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                    ) : (
                                        <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                    )}

                                </button>
                                <div className='flex flex-col gap-2'>
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder='| Password'
                                        className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                            </div>
                            <button onClick={handleSignUp} className="font-bold w-full text-white bg-dashBlue hover:bg-dashBlueDark focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-xl text-2xl px-5 py-3.5 text-center">Sign Up</button>
                            <div className='m-auto'>
                                <div className="flex flex-col gap-4 text-center">
                                    <div className="flex gap-4 text-lg font-semibold text-center text-white mil:text-black">
                                        <div className='hover:underline cursor-pointer underline-offset-4 text-blue-400' onClick={() => setSignUp(false)}>
                                            Já tenho uma conta
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex mil:hidden justify-end pt-20 px-5  cinco:px-10 mile400:px-15'>
                        <p className='w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
                    </div>
                </div>
            </div>
            <div className={`relative items-center justify-center transition-all duration-700 ease-in-out ${signUp ? "translate-x-[-85%]" : ""} w-[55%] min-h-screen h-full hidden mil:flex bg-[var(--primary)]`}>
                <div className='flex items-center justify-center h-full w-[30rem]'>
                    <img src={modules} alt="Modules" />
                </div>
                <p className='absolute bottom-20 right-20 w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
            </div>
            <Loading isOpen={loading} />
        </div>
    );
}